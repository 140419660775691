<i18n>
{
  "en": {
    "links": "Useful links"
  },
  "fr": {
    "links": "Liens utiles"
  }
}
</i18n>

<template>
  <v-list class="transparent">
    <v-subheader>
      {{ $t('links') }}
    </v-subheader>
    <v-list-tile
      v-for="link in links"
      :key="link.id"
      :href="link.value"
      target="_blank"
    >
      <v-list-tile-action>
        <v-icon> mdi-link </v-icon>
      </v-list-tile-action>
      <v-list-tile-title class="body-1">
        <span>{{ link | title }}</span>
      </v-list-tile-title>
    </v-list-tile>
  </v-list>
</template>

<script>
import { $data } from '~/utils/spoke'

export default {
  filters: {
    title(link) {
      return $data(link, 'metadatas.title', link.value)
    },
  },
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
