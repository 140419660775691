<i18n>
{
  "en": {
    "comment": "Add a reaction"
  },
  "fr": {
    "comment": "Ajouter une réaction"
  }
}
</i18n>

<template>
  <div>
    <v-card
      class="w-player-mobile"
      flat
      tile
      :color="$voicer.isDarkTheme ? '#212121' : '#fafafa'"
      :class="{ 'w-player-mobile--theme--dark': $voicer.isDarkTheme }"
    >
      <div class="w-player-mobile__image-controller-wrapper">
        <v-img
          :lazy-src="headingImg.lazySrc"
          :src="headingImg.cdnSrc"
          :gradient="$voicer.getImageGradient()"
          :aspect-ratio="
            $voicer.getThemeConfigProperty('component.card.aspectRatio', 1.778)
          "
          class="w-player-mobile__image-container"
        >
          <v-layout
            slot="placeholder"
            fill-height
            align-center
            justify-center
            ma-0
          >
            <v-progress-circular indeterminate color="accent lighten-5" />
          </v-layout>
          <v-container v-if="closabled" class="shadowed-top pa-0">
            <v-layout row align-center justify-space-between>
              <v-btn icon @click.prevent="handleClose()">
                <v-icon
                  :class="
                    $voicer.isThemeDark()
                      ? 'white--text'
                      : 'grey--text text--darken-3'
                  "
                >
                  mdi-chevron-left
                </v-icon>
              </v-btn>
            </v-layout>
          </v-container>
        </v-img>
      </div>

      <div class="w-player-mobile__window">
        <div class="w-player-mobile__avatar">
          <div class="w-player-mobile__avatar__holder">
            <v-avatar size="72">
              <v-img :src="thumbnailImg.cdnSrc" />
            </v-avatar>
          </div>
        </div>
        <v-card-text class="text-xs-center pb-5 mb-2">
          <h1 class="title mb-2 pl-2 pr-2">
            {{ title }}
          </h1>
          <h3 v-if="baseline" class="subtitle-2" v-text="baseline" />
          <p class="body-1">
            {{ episodeTitle }}
          </p>
          <w-card-actions :content="content" />
          <v-layout
            class="w-player-mobile__controller mt-4"
            align-center
            justify-center
          >
            <w-button-index
              v-if="!isLive"
              :props="{
                size: 'large',
              }"
              orientation="prev"
            />
            <w-button-play
              :content="content"
              :props="buttonPlayProps"
              class="w-player-mobile__button-play"
              highlighted
              type="player"
            />
            <w-button-index
              v-if="!isLive"
              :props="{
                size: 'large',
              }"
              orientation="next"
            />
          </v-layout>
          <v-layout v-if="!isLive" align-center justify-space-around>
            <div class="w-player-mobile__tracker">
              <w-player-timer type="current" />
              <w-player-progress :absolute="false" :slider="true" />
              <w-player-timer />
            </div>
          </v-layout>
          <div class="summary body-1">
            <div
              :class="{ 'mb-5': sumEpisodes > 1 }"
              class="summary__text mt-3"
              v-html="fulltext"
            />
          </div>
          <w-player-chapters v-if="hasEpisodes" />
          <w-documents v-if="hasDocuments" :documents="documents" />
          <w-links v-if="hasLinks" :links="links" />
        </v-card-text>
      </div>
    </v-card>
    <portal to="before-container">
      <v-bottom-nav
        v-if="hasCommentsSystem || $voicer.enabledComponent('wButtonLike')"
        id="w-player-mobile__bottom-nav"
        v-model="bottomNavSelection"
        class="w-player-mobile__bottom-nav d-flex justify-center"
        :class="{
          'w-player-mobile__bottom-nav--theme-dark': $voicer.isDarkTheme,
        }"
        fixed
        grow
        hide-slider
        :color="
          $voicer.isDarkTheme ? 'grey lighten-2' : 'grey lighten-4 elevation-3'
        "
      >
        <w-comments-floating-button
          v-if="hasCommentsSystem"
          :props="{
            flat: true,
            color: $voicer.isDarkTheme ? 'grey lighten-2' : 'grey darken-3',
          }"
        >
          <span v-text="$t('comment')"></span>
          <v-icon
            :color="$voicer.isDarkTheme ? 'grey lighten-2' : 'grey darken-3'"
            v-text="'mdi-comment'"
          />
        </w-comments-floating-button>

        <w-button-like
          :is-reacted="isReacted"
          :props="{
            icon: false,
            flat: true,
          }"
          mode="text"
          :size="24"
          @react="handleReact"
          @unreact="handleUnreact"
        />
      </v-bottom-nav>
    </portal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import contentInfosMixin from '~/mixins/contentInfos'
import WCardActions from '~/components/WVoicyCard/WCardActions'
import WPlayerProgress from '~/components/WPlayerProgress'
import WPlayerTimer from '~/components/WPlayerTimer'
import WButtonPlay from '~/components/WButtonPlay'
import WButtonIndex from '~/components/WButtonIndex'
import WButtonLike from '~/components/WButtonLike'
import WDocuments from '~/components/WDocuments'
import WLinks from '~/components/WLinks'
import WPlayerChapters from '~/components/WPlayerChapters/WMobile'
import WCommentsFloatingButton from '~/components/WCommentsFloatingButton'

export default {
  components: {
    WCardActions,
    WCommentsFloatingButton,
    WPlayerProgress,
    WPlayerTimer,
    WButtonPlay,
    WButtonIndex,
    WButtonLike,
    WDocuments,
    WLinks,
    WPlayerChapters,
  },

  mixins: [contentInfosMixin],

  props: {
    closabled: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    bottomNavSelection: -1,
  }),

  computed: {
    ...mapGetters({
      isLoading: 'player/isLoading',
      isLoaded: 'player/isLoaded',
      isPlaying: 'player/isPlaying',
      hasCommentsSystem: 'player/hasCommentsSystem',
      episodes: 'player/episodes',
    }),
    buttonPlayProps() {
      const styleWhenLive = {
        fab: true,
        icon: true,
        large: true,
        color: 'primary',
      }
      const styleWhenNotLive = {
        color: 'primary',
        fab: true,
        icon: true,
        large: true,
      }
      return this.isLive ? styleWhenLive : styleWhenNotLive
    },
  },

  methods: {
    ...mapActions({
      react: 'contents/react',
      unreact: 'contents/unreact',
    }),
    handleClose() {
      this.$router.push(this.$voicer.getLastVisitedRoute().fullPath)
    },
    handleReact() {
      this.react({
        content: this.content,
      })
    },
    handleUnreact() {
      this.unreact({
        content: this.content,
      })
    },
  },
}
</script>

<style lang="scss">
.w-player-mobile__bottom-nav {
  z-index: 10 !important;

  &--theme-dark {
    .v-btn {
      color: rgba(33, 33, 33, 0.85) !important;
    }
  }

  .v-btn {
    color: rgba(255, 255, 255, 0.7) !important;
  }

  .v-btn--active {
    padding-top: 8px;
  }
  .v-btn--active:before {
    background-color: transparent; //currentColor;
  }
  .v-btn--active .v-btn__content {
    font-size: 12px !important;
  }
}

.w-player-mobile {
  position: fixed;
  top: 54px;
  left: 0;
  width: 100%;
  bottom: 0;
  overflow-y: auto;
  z-index: 5;

  &__avatar {
    display: flex;
    justify-content: center;

    &__holder {
      border: 5px solid black;
      border-radius: 100%;
    }
  }

  &--theme-dark {
    &_avatar__holder {
      border-color: white;
    }
  }

  &__window {
    position: relative;
    top: -40px;
  }

  .w-card-actions {
    margin: 0 auto;
    justify-content: center;
  }

  .w-player-timer {
    max-width: 50px;
  }

  .w-player-progress-slider {
    max-width: 200px;
    margin: 0 16px;
  }

  &__image-container {
    position: relative;
  }

  &__image-controller-wrapper {
    position: relative;
  }

  &__controller {
    display: flex;
    justify-content: center;
  }

  &__bottom-actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__button-play {
    margin: 0 32px;
  }

  &__tracker {
    margin: 0 140px;
    display: flex;
    justify-content: center;
    align-items: center;

    .w-player-timer {
      font-weight: bold;
    }
    .w-player-progress-slider {
      position: relative;
      top: 3px;
    }
  }

  .summary {
    padding: 16px;
    text-align: left;
    &__text {
      white-space: pre-wrap;
      word-break: break-word;
      text-overflow: unset;
      overflow: hidden;
    }
  }
}
</style>
