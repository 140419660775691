var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',_vm._b({staticClass:"w-highlighted",class:( _obj = {}, _obj['w-highlighted--mobile'] = _vm.$voicer.isMobile, _obj ),attrs:{"flat":""},on:{"click":_vm.handleSelect}},'v-card',_vm.__cardOptions,false),[_c('v-img',{attrs:{"lazy-src":_vm.headingImg.lazySrc,"src":_vm.headingImg.cdnSrc,"gradient":_vm.$voicer.getImageGradient(),"height":_vm.$voicer.isMobile ? '100%' : '390',"aspect-ratio":_vm.$voicer.getThemeConfigProperty('component.card.aspectRatio', 800 / 390)}},[(_vm.isLive)?_c('span',{staticClass:"live-label red body-2 white--text"},[_vm._v(_vm._s(_vm.$t('live')))]):_vm._e()]),_vm._v(" "),_c('v-card-title',{staticClass:"w-highlighted__content"},[_c('div',{staticClass:"pa-4"},[(_vm.$voicer.isDesktopOrTablet)?_c('div',{staticClass:"w-highlighted__content__avatar elevation-2"},[_c('v-avatar',{attrs:{"size":"84"}},[_c('v-img',{attrs:{"src":_vm.thumbnailImg.cdnSrc}})],1)],1):_vm._e(),_vm._v(" "),_c('v-card-title',{class:{
          'white--text': _vm.$voicer.isDarkTheme,
          'black--text': _vm.$voicer.isLightTheme,
        },attrs:{"primary-title":""}},[_c('h2',{staticClass:"w-highlighted__content__title font-weight-medium",class:{
            'display-2': _vm.$vuetify.breakpoint.mdAndUp,
            headline: _vm.$vuetify.breakpoint.smAndDown,
          }},[_vm._v("\n          "+_vm._s(_vm.title)+"\n        ")]),_vm._v(" "),(_vm.baseline)?_c('h3',{staticClass:"w-highlighted__content__baseline subtitle-2",domProps:{"textContent":_vm._s(_vm.baseline)}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"w-highlighted__content__infos mt-2"},[_c('w-card-actions',{staticClass:"w-highlighted__content__actions pa-0 white--text",attrs:{"content":_vm.content}}),_vm._v(" "),_c('div',{staticClass:"w-highlighted__content__player"},[_c('w-button-play',{attrs:{"content":_vm.content,"icon":false,"props":{
              outlined: true,
              color: 'primary',
              depressed: true,
              large: true,
            }},scopedSlots:_vm._u([{key:"stopped",fn:function(){return [_vm._v("\n              "+_vm._s(_vm.$t('listen'))+" · \n              "),(_vm.isLive === false)?_c('span',[_vm._v(_vm._s(_vm.duration))]):_c('span',[_vm._v(_vm._s(_vm.$t('live')))])]},proxy:true},{key:"playing",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s(_vm.$t('stop'))}}),_vm._v(" "),_c('v-icon',[_vm._v("mdi-stop")])]},proxy:true},{key:"error",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s(_vm.$t('error'))}}),_vm._v(" "),_c('v-icon',[_vm._v("mdi-error")]),_vm._v("@\n            ")]},proxy:true},{key:"loading",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s(_vm.$t('loading'))}})]},proxy:true}])})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }