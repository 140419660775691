<template>
  <v-dialog v-model="open" width="750" max-width="100%" persistent scrollable>
    <v-card
      class="mx-auto"
      :class="{
        'w-bk-form-modal': true,
        'w-bk-form-modal--mobile': $voicer.isMobile,
      }"
    >
      <v-toolbar flat class="primary" dark>
        <v-spacer />
        <v-btn icon small @click.stop="open = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-img
        :src="
          $voicer.isMobile
            ? require('~/assets/images/popupmobilemusic.png')
            : require('~/assets/images/popupdesktopmusic.png')
        "
        alt="image fete musique"
        :max-height="$voicer.isMobile ? 368 : undefined"
        @click="handleClick"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import localstore from 'store'
export default {
  data() {
    const lastOpen = localstore.get('exc_dialog_last_open') || null
    let open = false
    if (!lastOpen) open = true
    else {
      const now = new Date()
      const lastOpenDate = new Date(lastOpen)
      lastOpenDate.setDate(lastOpenDate.getDate() + 1)
      if (lastOpenDate < now) {
        open = true
      }
    }
    if (open === true) {
      localstore.set('exc_dialog_last_open', new Date())
      this.$store.dispatch('player/closePersistantPlayer')
    }
    return {
      open,
    }
  },
  watch: {
    open(status) {
      if (status === false) {
        this.$store.dispatch('player/openPersistantPlayer')
      }
    },
  },
  methods: {
    handleClick() {
      this.open = false
      this.$emit('open-form')
    },
  },
}
</script>

<style scoped>
.w-bk-form-modal {
  cursor: pointer;
}
</style>
