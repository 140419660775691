var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"w-player-mobile",class:{ 'w-player-mobile--theme--dark': _vm.$voicer.isDarkTheme },attrs:{"flat":"","tile":"","color":_vm.$voicer.isDarkTheme ? '#212121' : '#fafafa'}},[_c('div',{staticClass:"w-player-mobile__image-controller-wrapper"},[_c('v-img',{staticClass:"w-player-mobile__image-container",attrs:{"lazy-src":_vm.headingImg.lazySrc,"src":_vm.headingImg.cdnSrc,"gradient":_vm.$voicer.getImageGradient(),"aspect-ratio":_vm.$voicer.getThemeConfigProperty('component.card.aspectRatio', 1.778)}},[_c('v-layout',{attrs:{"slot":"placeholder","fill-height":"","align-center":"","justify-center":"","ma-0":""},slot:"placeholder"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"accent lighten-5"}})],1),_vm._v(" "),(_vm.closabled)?_c('v-container',{staticClass:"shadowed-top pa-0"},[_c('v-layout',{attrs:{"row":"","align-center":"","justify-space-between":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.handleClose()}}},[_c('v-icon',{class:_vm.$voicer.isThemeDark()
                    ? 'white--text'
                    : 'grey--text text--darken-3'},[_vm._v("\n                mdi-chevron-left\n              ")])],1)],1)],1):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"w-player-mobile__window"},[_c('div',{staticClass:"w-player-mobile__avatar"},[_c('div',{staticClass:"w-player-mobile__avatar__holder"},[_c('v-avatar',{attrs:{"size":"72"}},[_c('v-img',{attrs:{"src":_vm.thumbnailImg.cdnSrc}})],1)],1)]),_vm._v(" "),_c('v-card-text',{staticClass:"text-xs-center pb-5 mb-2"},[_c('h1',{staticClass:"title mb-2 pl-2 pr-2"},[_vm._v("\n          "+_vm._s(_vm.title)+"\n        ")]),_vm._v(" "),(_vm.baseline)?_c('h3',{staticClass:"subtitle-2",domProps:{"textContent":_vm._s(_vm.baseline)}}):_vm._e(),_vm._v(" "),_c('p',{staticClass:"body-1"},[_vm._v("\n          "+_vm._s(_vm.episodeTitle)+"\n        ")]),_vm._v(" "),_c('w-card-actions',{attrs:{"content":_vm.content}}),_vm._v(" "),_c('v-layout',{staticClass:"w-player-mobile__controller mt-4",attrs:{"align-center":"","justify-center":""}},[(!_vm.isLive)?_c('w-button-index',{attrs:{"props":{
              size: 'large',
            },"orientation":"prev"}}):_vm._e(),_vm._v(" "),_c('w-button-play',{staticClass:"w-player-mobile__button-play",attrs:{"content":_vm.content,"props":_vm.buttonPlayProps,"highlighted":"","type":"player"}}),_vm._v(" "),(!_vm.isLive)?_c('w-button-index',{attrs:{"props":{
              size: 'large',
            },"orientation":"next"}}):_vm._e()],1),_vm._v(" "),(!_vm.isLive)?_c('v-layout',{attrs:{"align-center":"","justify-space-around":""}},[_c('div',{staticClass:"w-player-mobile__tracker"},[_c('w-player-timer',{attrs:{"type":"current"}}),_vm._v(" "),_c('w-player-progress',{attrs:{"absolute":false,"slider":true}}),_vm._v(" "),_c('w-player-timer')],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"summary body-1"},[_c('div',{staticClass:"summary__text mt-3",class:{ 'mb-5': _vm.sumEpisodes > 1 },domProps:{"innerHTML":_vm._s(_vm.fulltext)}})]),_vm._v(" "),(_vm.hasEpisodes)?_c('w-player-chapters'):_vm._e(),_vm._v(" "),(_vm.hasDocuments)?_c('w-documents',{attrs:{"documents":_vm.documents}}):_vm._e(),_vm._v(" "),(_vm.hasLinks)?_c('w-links',{attrs:{"links":_vm.links}}):_vm._e()],1)],1)]),_vm._v(" "),_c('portal',{attrs:{"to":"before-container"}},[(_vm.hasCommentsSystem || _vm.$voicer.enabledComponent('wButtonLike'))?_c('v-bottom-nav',{staticClass:"w-player-mobile__bottom-nav d-flex justify-center",class:{
        'w-player-mobile__bottom-nav--theme-dark': _vm.$voicer.isDarkTheme,
      },attrs:{"id":"w-player-mobile__bottom-nav","fixed":"","grow":"","hide-slider":"","color":_vm.$voicer.isDarkTheme ? 'grey lighten-2' : 'grey lighten-4 elevation-3'},model:{value:(_vm.bottomNavSelection),callback:function ($$v) {_vm.bottomNavSelection=$$v},expression:"bottomNavSelection"}},[(_vm.hasCommentsSystem)?_c('w-comments-floating-button',{attrs:{"props":{
          flat: true,
          color: _vm.$voicer.isDarkTheme ? 'grey lighten-2' : 'grey darken-3',
        }}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('comment'))}}),_vm._v(" "),_c('v-icon',{attrs:{"color":_vm.$voicer.isDarkTheme ? 'grey lighten-2' : 'grey darken-3'},domProps:{"textContent":_vm._s('mdi-comment')}})],1):_vm._e(),_vm._v(" "),_c('w-button-like',{attrs:{"is-reacted":_vm.isReacted,"props":{
          icon: false,
          flat: true,
        },"mode":"text","size":24},on:{"react":_vm.handleReact,"unreact":_vm.handleUnreact}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }