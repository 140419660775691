<i18n>
{
  "en": {
    "alt_logo": "iOS share icon",
    "download": "Download the app",
    "instructions_1": "To install the application, click on",
    "instructions_2": "and add to the home screen"
  },
  "fr": {
    "alt_logo": "icone de partage iOS",
    "download": "Télécharger l'application",
    "instructions_1": "Pour installer l'application, cliquez sur",
    "instructions_2": "puis ajouter à l'écran d'accueil"
  }
}
</i18n>

<template>
  <v-bottom-sheet v-model="open" class="w-ath">
    <v-list class="w-ath-safari" three-line>
      <v-list-tile>
        <v-list-tile-avatar tile>
          <w-logo size="24" no-link />
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title class="caption">
            {{ $t('download') }}
          </v-list-tile-title>
          <v-list-tile-sub-title class="caption">
            {{ $t('instructions_1') }}
            <icon-share-ios
              size="18px"
              :color="$voicer.isDarkTheme ? 'white' : 'black'"
            />
            {{ $t('instructions_2') }}
          </v-list-tile-sub-title>
        </v-list-tile-content>

        <v-list-tile-action>
          <v-icon small @click.passive="handleClose"> mdi-close </v-icon>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
  </v-bottom-sheet>
</template>

<script>
import IconShareIos from '~/components/Icons/ShareIos'

export default {
  components: {
    IconShareIos,
  },
  data() {
    return {
      open: this.$voicer.isIos && this.$voicer.isStandalone === false,
    }
  },

  methods: {
    handleClose() {
      this.open = false
    },
  },
}
</script>

<style lang="scss">
.w-ath-safari {
  .v-list__tile__content
    ~ .v-list__tile__action:not(.v-list__tile__action--stack) {
    align-items: flex-start;
    padding: 8px 0;
  }
  .w-logo {
    width: 28px !important;
    height: 28px !important;
  }
}
</style>
