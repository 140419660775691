<template>
  <v-btn
    fab
    large
    class="exc-button-play"
    :class="rootClass"
    :disabled="isLoadingContent || isErrorContent"
    color="primary"
    @click.stop="handleClick"
  >
    <v-progress-circular
      v-if="isLoadingContent"
      indeterminate
      large
      color="grey"
    />
    <v-icon v-else-if="icon && isPlayingContent" key="icon-pause" large>
      mdi-pause
    </v-icon>
    <v-icon v-else-if="icon && isError" key="icon-error" large>mdi-play</v-icon>
    <v-icon v-else-if="icon" key="icon-play" large>mdi-play</v-icon>
  </v-btn>
</template>

<script>
import mediaIntelligence from './media-intelligence'

export default {
  mixins: [mediaIntelligence],
  computed: {
    rootClass() {
      return {
        'exc-button-play--desktop': this.$voicer.isDesktopOrTablet,
        'exc-button-play--mobile': this.$voicer.isMobile,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$btn_size_desktop: 118px;
$btn_size_mobile: 98px;

.exc-button-play {
  border: 4px white solid !important;

  &--desktop {
    width: $btn_size_desktop !important;
    height: $btn_size_desktop !important;

    & > .v-btn__content > i {
      font-size: 64px !important;
    }
  }

  &--mobile {
    width: $btn_size_mobile !important;
    height: $btn_size_mobile !important;

    & > .v-btn__content > i {
      font-size: 52px !important;
    }
  }
}
</style>
