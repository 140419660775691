<i18n>
{
  "fr": {
    "add_favorits": "Ajouter aux favoris",
    "remove_favorits": "Dans vos favoris"
  },
  "en": {
    "add_favorits": "Add to favorits",
    "remove_favorits": "In your favorits"
  }
}
</i18n>

<template>
  <v-btn
    v-if="$voicer.enabledComponent('wButtonLike')"
    v-bind="props"
    :disabled="informative"
    icon
    class="w-button-like"
    @click="handleLike"
  >
    <span v-if="mode === 'text'" v-text="hintText" />
    <v-icon :size="size" :color="color">
      {{ isReacted ? 'mdi-heart' : 'mdi-heart-outline' }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    isReacted: {
      type: Boolean,
      default: false,
    },
    informative: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
      default: () => ({}),
    },
    size: {
      type: Number,
      default: 14,
    },
    mode: {
      type: String,
      default: 'default',
    },
  },

  computed: {
    color() {
      const baseColor = this.$voicer.isDarkTheme
        ? 'grey lighten-1'
        : 'grey darken-3'
      const reactedColor = this.$voicer.isDarkTheme
        ? 'red darken-1'
        : 'red lighten-1'

      return this.isReacted ? reactedColor : baseColor
    },
    hintText() {
      return this.$t(this.isReacted ? `remove_favorits` : 'add_favorits')
    },
  },

  methods: {
    handleLike(e) {
      e.stopPropagation()

      this.isReacted ? this.$emit('unreact') : this.$emit('react')
    },
  },
}
</script>

<style lang="scss">
.w-button-like {
  align-self: inherit;

  .v-btn {
    position: relative;
    z-index: 1;
  }
}
</style>
