<template>
  <v-dialog
    v-model="open"
    :fullscreen="$voicer.isMobile"
    :max-width="$voicer.isDesktopOrTablet ? '640px' : 'auto'"
  >
    <v-form v-model="valid" @submit.prevent="handleSubmit">
      <v-card
        class="exc-contact-form"
        :class="{ ['exc-contact-form--mobile']: $voicer.isMobile }"
      >
        <div class="exc-contact-form__close">
          <v-icon @click="close"> mdi-close </v-icon>
        </div>
        <div class="exc-contact-form__head">
          <h2
            :class="{
              ['display-3']: $voicer.isDesktopOrTablet,
              ['display-2']: $voicer.isMobile,
            }"
          >
            Lâche ta dédicace
          </h2>
        </div>
        <v-card-text class="exc-contact-form__body">
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 md12>
                <v-text-field
                  v-model="form.firstname"
                  outline
                  label="Ton prénom"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  v-model="form.lastname"
                  outline
                  label="Ton nom"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  v-model="form.mail"
                  outline
                  label="Ton adresse mail"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  v-model="form.city2"
                  outline
                  label="Le nom de ton resto"
                  required
                >
                  /></v-text-field
                >
              </v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  v-model="form.message"
                  outline
                  label="Ton message"
                  required
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions class="exc-contact-form__actions">
          <div class="exc-contact-form__actions__output">
            <div v-if="loading">En cours d'envoi...</div>
            <div v-else-if="sysError" class="red--text">
              Une erreur est survenue
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            type="submit"
            color="primary"
            large
            depressed
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    data: () => ({}),
    form: {
      lastname: '',
      firstname: '',
      city1: '',
      city2: '',
      mail: '',
      message: '',
    },
    sysError: false,
    loading: false,
    valid: true,
  }),
  computed: {
    open: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate()
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },

    ...mapActions({
      notify: 'notifier/setSuccess',
    }),
    async handleSubmit() {
      this.loading = true

      try {
        await axios.post(
          'https://europe-west1-bbcast-interface.cloudfunctions.net/BKRadioSendMail',
          {
            ...this.form,
          }
        )
        this.open = false
        this.notify('Merci pour ta dédicace !')
      } catch (error) {
        this.sysError = true
        this.$voicer.captureException(error)
      } finally {
        this.loading = false
      }
    },
    close() {
      this.open = false
    },
  },
}
</script>

<style lang="scss" scoped>
.exc-contact-form {
  $rootClass: &;

  display: flex !important;
  flex-direction: column;

  &--mobile {
    #{$rootClass}__head {
      padding: 20px 0;
    }
  }

  &__close {
    align-self: flex-end;
    padding: 12px;
  }

  &__head {
    flex: 0 0 0;
    padding: 0 0 40px 0;
    text-align: center;

    h2 {
      text-transform: uppercase;
      font-family: 'Flame Bold' !important;
    }
  }

  &__body {
    align-self: auto;
    flex: 1 1;
  }

  &__actions {
    flex: 0 0 0;
  }
}
</style>
