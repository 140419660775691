<i18n>
{
  "en": {
    "documents": "Documents of interest"
  },
  "fr": {
    "documents": "Documents utiles"
  }
}
</i18n>

<template>
  <v-list class="transparent">
    <v-subheader>
      {{ $t('documents') }}
    </v-subheader>
    <v-list-tile
      v-for="document in documents"
      :key="document.id"
      @click="handleDownload(document)"
    >
      <v-list-tile-action>
        <v-icon> mdi-download </v-icon>
      </v-list-tile-action>
      <v-list-tile-title class="body-1">
        {{ document | title }}
      </v-list-tile-title>
    </v-list-tile>
  </v-list>
</template>

<script>
import spoke, { $data } from '~/utils/spoke'
import mixpanelHelper from '~/utils/mixpanel'

export default {
  filters: {
    title(document) {
      return $data(document, 'metadatas.title', 'Sans titre')
    },
  },
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleDownload(document) {
      spoke.document(document).$download()
      mixpanelHelper.download(document)
    },
  },
}
</script>

<style lang="scss" scoped>
// Insert super powers here
</style>
