import { render, staticRenderFns } from "./WWavy.vue?vue&type=template&id=13a1e947&"
import script from "./WWavy.vue?vue&type=script&lang=js&"
export * from "./WWavy.vue?vue&type=script&lang=js&"
import style0 from "./WWavy.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTab } from 'vuetify/lib/components/VTabs';
installComponents(component, {VAvatar,VIcon,VImg,VLayout,VProgressCircular,VTab})
