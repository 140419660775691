<i18n>
{
  "en": {
    "search": "Search",
    "search_placeholder": "Search"
  },
  "fr": {
    "search": "Recherche",
    "search_placeholder": "Rechercher"
  }
}
</i18n>

<template>
  <div
    :class="{
      ['w-toolbar-search--mobile']: $vuetify.breakpoint.xsOnly,
    }"
    class="w-toolbar-search"
  >
    <v-btn
      slot="activator"
      :icon="breakpoint.xsOnly"
      :flat="!breakpoint.xsOnly"
      :large="!breakpoint.xsOnly"
      @click.stop="handleOpen"
    >
      <v-icon>mdi-magnify</v-icon>
      <span v-if="!breakpoint.xsOnly" class="w-menu__item">{{
        $t('search')
      }}</span>
    </v-btn>

    <v-form
      v-show="open"
      ref="form"
      class="w-toolbar-search__form"
      @submit.prevent="handleSearch"
    >
      <v-text-field
        ref="input"
        v-model="form.input"
        :height="breakpoint.smAndUp ? 64 : 56"
        :label="$t('search_placeholder')"
        prepend-inner-icon="mdi-chevron-left"
        color="accent"
        clearable
        full-width
        solo
        @click:prepend-inner="handleCancel"
        @click:clear="handleReset"
        @click:append="handleSearch"
      />
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    const { q = '', mode = 'default' } = this.$route.query

    return {
      open: mode === 'search',
      isMounted: false,
      form: {
        input: q,
      },
    }
  },

  computed: {
    breakpoint() {
      return this.isMounted ? this.$vuetify.breakpoint : {}
    },
  },

  watch: {
    // if user click on a in-app link which redirect
    // on another page mode, we should reset search engine
    // with defaults values
    '$route.query'(value) {
      if (value.mode !== 'search') {
        this.open = false
        this.form.input = ''
      }
    },
  },

  mounted() {
    this.isMounted = true
  },

  methods: {
    handleCancel() {
      this.$store.dispatch('contents/resetSearchResult')
      this.$store.dispatch('filters/setInitialQueries', [])

      this.$router.push({
        path: this.$voicer.getConfig('searchCanceledRedirectTo', '/'),
        query: {
          q: undefined,
          mode: this.$voicer.getConfig(
            'searchCanceledRedirectToMode',
            undefined
          ),
        },
      })
      this.open = false
      this.form.input = ''
    },
    handleOpen() {
      this.open = true
      this.$nextTick(() => {
        this.$refs.input.$refs.input.focus()
      })
    },
    handleReset() {
      this.form.input = ''
    },
    handleSearch() {
      if (this.form.input.length === 0) {
        return this.handleCancel()
      }
      this.$store.dispatch('contents/resetSearchResult')

      this.$store.dispatch('filters/setInitialQueries', [this.form.input])
      // Send user to result page
      this.$router.push({
        path: this.$voicer.getConfig('redirectSearchTo', '/'),
        query: {
          q: this.form.input,
          mode: 'search',
          t: +new Date(),
        },
      })

      this.$refs.input.$refs.input.blur()
    },
  },
}
</script>

<style lang="scss">
.w-toolbar-search {
  height: inherit;

  button {
    height: inherit !important;
    margin: 0 !important;
  }

  &__form {
    z-index: 5;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    input {
      margin-top: 0 !important;
    }
  }

  &--mobile {
    &__form {
      right: 0;
      left: 0;
    }
  }

  .v-icon {
    position: relative;
    top: 2px;
  }

  .w-menu__item {
    margin-left: 8px;
  }
}
</style>
