<i18n>
{
  "en": {
    "chapters": "Chapters"
  },
  "fr": {
    "chapters": "Chapitres"
  }
}
</i18n>

<template>
  <v-list v-if="episodes.length > 1" class="transparent">
    <v-subheader>
      {{ $t('chapters') }}
    </v-subheader>
    <v-divider class="mb-2" />
    <v-list-tile
      v-for="(chapter, index) in episodes"
      :key="chapter.id"
      :disabled="isLoading && index === episodeIndex"
      @click="handleSelectChapter(index)"
    >
      <v-list-tile-action>
        <v-progress-circular
          v-if="isLoading && chapter.metadatas.position === episodeIndex"
          :size="24"
          :width="2"
          indeterminate
          color="grey lighten-1"
        />
        <v-icon
          v-else-if="isPlaying && chapter.metadatas.position === episodeIndex"
          color="grey lighten-1"
        >
          mdi-pause
        </v-icon>
        <v-icon v-else color="grey lighten-1"> mdi-play </v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title class="body-1">
          {{ chapter.metadatas.title }}
        </v-list-tile-title>
        <v-list-tile-sub-title class="body-1">
          {{ formatTime(chapter.metadatas.duration) }}
        </v-list-tile-sub-title>
      </v-list-tile-content>
    </v-list-tile>
  </v-list>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import formatTime from '~/mixins/formatTime'

export default {
  mixins: [formatTime],

  computed: {
    ...mapState({
      episodeIndex: (state) => state.player.episodeIndex,
    }),
    ...mapGetters({
      isLoading: 'player/isLoading',
      isPlaying: 'player/isPlaying',
      episodes: 'player/episodes',
    }),
  },

  methods: {
    ...mapActions({
      changePlayerEpisode: 'player/changePlayerEpisode',
      ctrlPlayer: 'player/ctrlPlayer',
    }),

    handleSelectChapter(episodeIndex) {
      if (this.isPlaying && episodeIndex === this.episodeIndex) {
        this.$emit('pause')
        this.ctrlPlayer('pause')
      } else {
        this.$emit('play')
        this.changePlayerEpisode(episodeIndex)
        this.ctrlPlayer({
          action: 'play',
          episodeIndex,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// Fix large space after action elements
.v-list__tile__action {
  min-width: 38px;
}

.v-list--three-line .v-list__tile {
  height: 10px;
}
</style>
